import React, { FC } from 'react'
import { Link } from 'gatsby'

import { Header } from '../components/header'
import { Footer } from '../components/footer'
import { inszuAppURL } from '../config'

interface GetYourMoneyBackProps {}

const GetYourMoneyBack: FC<GetYourMoneyBackProps> = () => {
  return (
    <main>
      <Header />
      <div className="container px-4 mx-auto">
        <div className="flex flex-col py-4 lg:flex-row-reverse lg:py-10">
          <section className="mb-8 lg:flex-1 lg:ml-8">
            <div className="embed-container">
              <iframe
                title="Get your money back"
                src="https://player.vimeo.com/video/713109103"
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
              ></iframe>
            </div>
          </section>
          <article className="lg:flex-1">
            <div className="mb-4 text-sm tracking-widest uppercase">learn more</div>
            <h1 className="mb-6 text-2xl font-bold leading-relaxed lg:mb-8 lg:text-4xl text-slate-900">
              Get your money
              <br />
              back
            </h1>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              When you think of owning a life insurance policy, getting a return on your money isn't generally something
              that comes to your mind. We offer “Return of Premium” (ROP) life insurance that pays back the money you
              spent on monthly premiums if you were to outlive the term of your coverage.
            </p>
            <h2 className="mb-4 text-lg font-bold lg:text-2xl">How does “Return of Premium” life insurance work?</h2>
            <ul className="pl-4 mb-8 list-disc lg:mb-10">
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">
                Select the term length for your coverage (ROP available on 20 and 30-year terms only).
              </li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">
                If you pass away during that time, your beneficiaries will receive a tax-free death benefit.
              </li>
              <li className="mb-4 leading-relaxed lg:text-lg text-ebony/70">
                If you outlive your policy term you will receive a 75% refund of all premiums paid.
              </li>
            </ul>
            <h2 className="mb-4 text-lg font-bold lg:text-2xl">Here’s an example:</h2>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              Bill is a 35 year old male who has a 20 year ROP life insurance term policy who pays a monthly premium of
              $70/mo. Over the course of his 20 year term, Bill has paid $16,800 in premiums. Because he hasn’t
              experienced any health issues during his term, he received a check from the insurance carrier for $12,600
              tax-free.
            </p>
            <p className="mb-8 leading-relaxed lg:mb-10 lg:text-lg text-ebony/70">
              This is what we call, “making your life insurance work for you.” Enjoy your protected years knowing that
              you never again have to worry about just throwing your money away after your life insurance policy
              expires.
            </p>
            <div>
              <Link
                to={inszuAppURL}
                className="px-6 py-4 text-white transition bg-orange-600 rounded-lg appearance-none motion-reduce:transition-none hover:bg-orange-700"
              >
                Get Started
              </Link>
            </div>
          </article>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default GetYourMoneyBack
